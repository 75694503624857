import React from 'react'
import houseUrl from './haus-sommer.jpg'
import style from './style.module.css'
import koenigscard from './koenigscard.jpg'
import dtvLogo from './dtv-logo.jpg'
import nesselwangLogo from './nesselwang-logo.png'
import Envelope from '../components/icons/Envelope'
import Phone from '../components/icons/Phone'
import User from '../components/icons/User'
import Location from '../components/icons/Location'
import Layout from '../layouts'

const IndexPage = () => (
  <Layout>
    <div className={style.content}>
      <div className={style.left}>
        <img src={houseUrl} alt="" />
        <span>
          <User /> Alois Allgaier <br />
        </span>
        <span>
          <Location /> Wank 3, 87484 Nesswelang <br />
        </span>
        <span>
          <Phone /> Telefon: 08361 3998 <br />
        </span>
        <span>
          <Envelope /> E-Mail: info@fewo-allgaier.de
        </span>
      </div>
      <div>
        <h2>Die Ferienwohnung</h2>
        <p>
          Wir bieten Ihnen eine großzügige 4-Sterne-Ferienwohnung mit 5 Zimmern
          und viel Platz (140 m2) in unserem Haus im Ortsteil Wank (ca. 1,5km
          zum Ortskern).
        </p>
        <p>
          Die Wohnung verfügt über ein Spielzimmer, großes Wohnzimmer, eine
          komplette Einbauküche inkl. Spülmaschine, Bad / Dusche/ WC, 3
          Schlafzimmer, Farb-TV, Waschküche mit Waschmaschine und Trockner.
        </p>
        <p>
          Sie ist ein idealer Ausgangspunkt für Wanderungen und Ausflüge.
          Bademöglichkeiten bieten die Naturseen in der Umgebung. Im Winter kann
          der Loipeneinstieg in unmittelbarer Nähe erfolgen.
        </p>
        <p>
          Heizung, Wasser, Strom, Küchen- und Tischwäsche und Handtücher sind
          inklusive. Der Kurbeitrag wird vor Ort separat abgerechnet.
        </p>
        <p>
          Bei uns erhalten Sie die Zusatzleistungen der Königscard zum
          Nulltarif.
          <br />
          Hier noch der Faltprospekt zum{' '}
          <a target="blank" href={'./Faltprospekt.pdf'}>
            Download
          </a>.
        </p>
        <div className={style.logos}>
          <a href="http://www.koenigscard.com" target="blank">
            <img src={koenigscard} alt="Königscard Logo" />
          </a>
          <img src={dtvLogo} alt="DTV Logo" />
          <a href="http://www.nesselwang.de" target="blank">
            <img src={nesselwangLogo} alt="Nesselwang Logo" />
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
